@use 'fonts'
@use '~include-media/dist/_include-media.scss'
// Ironically, stylelint can't handle the colours.$colour-name syntax.
// So rather than @use we have to @import (or abandon the linting)
@import 'colours'
@import 'shadows'

// Change the size of the containers
@media (min-width: 1200px)
  .container
      max-width: 1250px

.font-size-1-5rem
  font-size: 1.5rem

html
  font-family: Arial, Helvetica, sans-serif
  -ms-text-size-adjust: 100% /* stylelint-disable-line */ // Not standardized
  -webkit-text-size-adjust: 100% /* stylelint-disable-line */ // Not standardized

body
  margin: 0

figure, header, menu, nav, section
  display: block

a
  background-color: transparent

  &:active, &:hover
    outline: 0

  &:link, &:visited, &:hover, &:active
    color: #003247

small
  font-size: 80%

img
  border: 0

figure
  margin: 1em 40px

button, input
  color: inherit
  font: inherit
  margin: 0

button
  overflow: visible
  text-transform: none
  -webkit-appearance: button /* stylelint-disable-line */
  cursor: pointer

  &::-moz-focus-inner
    border: 0
    padding: 0

input
  line-height: 1.1

  &::-moz-focus-inner
    border: 0
    padding: 0


// This is so that the footer can stay at the bottom of the page, even when there isn't enough content to fill it.
html, body
  min-height: 100vh

html
  font-size: 62.5%

*
  box-sizing: border-box

  &::after, &::before
    box-sizing: border-box

.clearfix::after
  content: ''
  display: table
  clear: both

body
  padding: 0
  margin: 0
  background-color: #e8e8e4
  color: #003247
  // position: relative   Apparently not required
  // font-size: 1.2rem
  font-family: Arial, Helvetica, sans-serif
  font-weight: 400

  // ul
  //   font-size: 1.2em
  // ol
  //   font-size: 1.2em
  
p
  // font-size: 1.2em
  // line-height: 2.7rem
  margin: 0 0 2em 0

// Unused
// .wrapper
//   width: 100%
//   position: relative
//   overflow-x: hidden

section
  // position: relative  Apparently not required
  padding: 6em 1.5em

  &.dark
    background-color: #0b1d26
    background-size: cover
    background-attachment: fixed
    z-index: 0

h1
  font-family: NotesESAbold, sans-serif
  font-weight: 800
  font-size: 6rem
  line-height: 1.0

.video-header
  font-size: 6rem

.lead-promote
  font-family: NotesESAbold, sans-serif
  font-weight: 800
  font-size: 3rem
  padding-top: 2rem
  line-height: normal
  color: $esa-teal

.lead-video
  font-family: NotesESAbold, sans-serif
  font-weight: 800
  font-size: 2.2rem
  padding-top: 2rem
  line-height: normal

.lead-paragraph
  font-family: NotesESAregular, sans-serif
  font-weight: normal
  font-size: 2rem
  padding-top: 2rem
  line-height: normal
  
h2, h3, .pillar
  font-family: NotesESAbold, sans-serif
  font-weight: normal
  margin: 0
  margin-bottom: 2rem

h2
  font-weight: 800
  font-size: 4rem
  // color: $deepspace-blue
  color: #ffffff

h3
  font-size: 3.2rem

h4
  font-size: 2.4rem


.heading
  font-size: 2em
  line-height: 1.25
  margin-bottom: 1em

  &.section-heading
    font-size: 5rem
    margin: 0 0 1em

section.dark .heading.section-heading
  color: #e8e8e4

a
  text-decoration: none
  color: inherit

figure
  margin: 0

i
  font-style: italic

.dimmer
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  z-index: 1
  background-color: rgba(11, 29, 38, 0.5)

.card
  &.image .dimmer
    background-image: linear-gradient(to top, rgba(0, 50, 71, 0.8), rgba(0, 50, 71, 0))
    background-color: transparent

  &.story .dimmer, &.video .dimmer, &.focuson .dimmer
    height: 50%
    background-image: linear-gradient(to bottom, rgba(0, 50, 71, 0.2), rgba(0, 50, 71, 0))
    background-color: transparent

.image-bg
  position: absolute
  top: 0
  left: 0
  width: 100% !important
  height: 100% !important
  object-fit: cover
  z-index: 0
  transition: all 1s 0.05s ease

@media (min-width: 768px)
  section
    padding: 6em 1.5em 9em 1.5em

  .heading.section-heading
    font-size: 6rem

@media (min-width: 1024px)
  section
    padding: 6em 6em 9em 6em

  .heading.section-heading
    font-size: 7.5rem

@media (max-width: 374px)
  .heading.section-heading
    font-size: 4rem

@media (min-width: 1400px)
  .heading
    font-size: 3em

.nav
  height: 100%
  display: flex
  align-items: center
  justify-content: flex-start
  margin-right: auto
  font-size: 2.2rem
  margin-top: -19px

  > *
    margin-right: 20px

  button
    transition: all 0.25s ease

    &:hover
      transform: scale(1.1)

button
  width: auto
  padding: 0
  margin: 0
  background: no-repeat
  border: none
  outline: none

.cta
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  color: $deep-space1
  background-color: #fff
  width: 200px
  height: 50px
  margin-top: auto
  padding: 0 30px
  border-radius: 2px
  cursor: pointer
  transition: all 0.25s ease

  &:hover
    transform: translateY(-0.15rem)

  label
    font-weight: 500
    font-size: 1.2rem
    text-transform: uppercase
    letter-spacing: 0.35rem
    cursor: pointer

  .arrow
    height: 12px
    margin-left: auto

  &:hover .arrow
    animation: cta-arrow 1s ease-in-out infinite

.btn
  background-color: rgba(255, 255, 255, 0.85)
  color: $dark-green !important
  font-family: NotesESAbold, sans-serif 
  letter-spacing: normal
  font-size: 2rem
  text-transform: none
  margin-top: 5rem
  padding: 16px 42px
  border-radius: 4px
  gap: 10px
  border-bottom: none !important
  box-shadow: none !important
  
  &:hover
      transform: none !important
      color: $esa-teal !important
      background-color: #ffffff !important

  &.active
    color: #fff
    background-color: $deep-space1

    &:hover
      transform: none
      cursor: default


  &.more
    border-style: dashed
    padding: 6px 10px

.agency .btn
  border-color: #7b98a8

  &:hover, &.active
    background-color: #7b98a8

.applications .btn, .connecting-benefiting .btn
  border-color: #00b19d

.applications .btn:hover
  background-color: #00b19d

.connecting-benefiting .btn
  &:hover, &.active
    background-color: #00b19d

.science-exploration .btn, .exploring-discovering .btn
  border-color: $excite-red

.science-exploration .btn:hover
  background-color: $excite-red

.exploring-discovering .btn
  &:hover, &.active
    background-color: $excite-red

.enabling-support .btn, .designing-operating .btn
  border-color: #ffa800

.enabling-support .btn:hover
  background-color: #ffa800

.designing-operating .btn
  &:hover, &.active
    background-color: #ffa800

.monitoring-safeguarding .btn
  border-color: #009de1

  &:hover
    background-color: #009de1

.btn img
  margin-right: 0.5em

// Apparently unused
// .round-thumbnails
//   display: flex
//   flex-wrap: wrap
//   margin: -10px

// Apparently unused
// .round-thumbnail
//   flex: 1 0 auto
//   width: 150px
//   height: 150px
//   max-width: 150px
//   max-height: 150px
//   position: relative
//   border-radius: 100rem
//   display: inline-flex
//   justify-content: center
//   align-items: center
//   background-color: #003247
//   background-size: cover
//   color: #fff
//   font-family: 'NotesESAbold', sans-serif
//   font-weight: normal
//   font-size: 1.8rem
//   margin: 10px
//   overflow: hidden
//   -webkit-mask-image: -webkit-radial-gradient(#ffffff, #000000) /* stylelint-disable-line */
//
//   .image-bg
//     border-radius: 100rem
//
//   &:hover
//     cursor: pointer
//
//     .image-bg
//       transform: scale(1.2)
//
//   span
//     text-align: center
//     line-height: 1.2
//     transition: all 0.25s ease
//
//   &:hover span
//     transform: scale(1.15)
//
//   .dimmer
//     border-radius: 100rem
//
// @media (max-width: 374px)
//   .round-thumbnail
//     width: 130px
//     height: 130px
//     max-width: 130px
//     max-height: 130px

.social-media-links
  display: flex
  flex-direction: row
  font-size: 2.2rem

  > *
    margin-right: 1em
    transition: all 0.25s ease

    &:hover
      transform: scale(1.1)

    &:last-child
      margin-right: 0

// This, combined with margin-top: auto in #bottompage, make the footer stick to the bottom even on short pages.
body
  display: flex
  flex-direction: column

#footer
  border-top: 10px solid $deepspace-blue
  margin-top: auto
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  background-color: #fff
  padding-top: 3em
  padding-bottom: 3em
  padding-left: 1.5rem // Note: overridden below for windows wider than 1024px
  padding-right: 1.5rem
  z-index: 1

.footer-nav
  display: flex
  flex-direction: column
  font-family: 'NotesESAregular', sans-serif
  font-weight: normal
  margin-bottom: 1em
  font-size: 1.6rem

  > *
    margin: 1em 0
    width: fit-content

  a:hover
    text-decoration: underline

.footer__signature
  font-family: 'NotesESAbold', sans-serif
  font-size: 1.6rem
  margin: 0 2em 1em 0

@media (min-width: 1024px)
  #footer
    flex-direction: row
    align-items: center
    justify-content: flex-start
    padding-left: 6em
    padding-right: 6em
    z-index: 1

  .footer-nav
    flex-direction: row
    margin-bottom: 0
    margin-right: auto

    > *
      margin: 0 2em 0 0

  .social-media-links > *
    margin-right: 0
    margin-left: 1em

  .footer__signature
    margin: 0 4em 0 0

.button-group .heading
  color: #e8e8e4
  font-size: 3.6rem
  margin-bottom: 0.75em
  margin-top: 2em

.section-group
  .heading
    color: #e8e8e4
    font-size: 3.6rem
    margin-bottom: 0.75em
    margin-top: 2em

  &.button-group:not(.nested) + .section-group.button-group:not(.nested)
    margin-top: 4em

.button-group .btn
  margin: 0 7px 10px 0

.section-intro
  margin: 0 0 2em
  line-height: 1.5

@keyframes cta-arrow
  0%
    transform: translateX(0)

  50%
    transform: translateX(50%)

  100%
    transform: translateX(0)

.grid
  width: calc(100% + 40px)
  margin: -20px

.grid-item, .grid-sizer
  width: 340px

.entry, .entry-info, .cta, .play-button
  z-index: 2

.entry
  display: flex
  flex-direction: column
  justify-content: flex-end
  width: 100%

.entry-info
  width: 100%
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  margin-bottom: auto

  .category, .duration, .code
    text-transform: uppercase
    color: #fff
    font-size: 1.2rem
    letter-spacing: 0.25rem
    z-index: 2

  .duration, .code
    margin-left: auto

// Apparently unused, except obsolete publication_page.html
// .thumbnail
//   width: 100%
//   height: 100%
//   position: relative
//   display: flex
//   flex-direction: column
//   align-items: flex-start
//   justify-content: center
//   background-size: cover
//   background-position: center

.pillar
  display: inline-table
  width: max-content
  color: #ffffff
  background-color: #003247
  text-transform: uppercase
  padding: 3px 5px
  font-size: 1.3rem
  letter-spacing: 0.1rem

.agency .pillar
  background-color: #7b98a8

.connecting-benefiting .pillar
  background-color: #00b19d

.exploring-discovering .pillar
  background-color: $excite-red

.designing-operating .pillar
  background-color: #ffa800

.monitoring-safeguarding .pillar
  background-color: #009de1

.meta
  display: flex
  flex-direction: row
  color: #8197a6
  font-size: 1.2rem
  text-transform: uppercase

.pillar-gateway
  height: auto
  padding: 0
  background-color: #09171f
  overflow: hidden

.pillar-gateway--inner
  height: 100%
  display: flex
  flex-wrap: wrap
  flex-direction: column
  margin: -2px

.pillar-gateway--item
  height: 60px
  flex: 1 1 auto
  background-color: #0b1d26
  border-left: 5px solid transparent
  margin: 2px
  overflow: hidden
  padding: 17px 0
  text-align: center
  transition: all 0.5s ease

  &:hover
    background-color: #0f2733

  &.connecting-benefiting
    border-color: #00b19d

  &.exploring-discovering
    border-color: $excite-red

  &.designing-operating
    border-color: #ffa800

  &.monitoring-safeguarding
    border-color: #009de1

.pillar-gateway--entries
  display: flex
  justify-content: center
  margin: 20px 0

.pillar-gateway
  .pillar-toggle
    display: flex
    justify-content: flex-start
    align-items: center
    width: 100%

  .pillar
    font-size: 1.6rem
    margin-left: 3rem

  .pillar-toggle--arrow
    color: #e8e8e4
    font-size: 1.5em
    margin-left: auto
    margin-right: 3rem
    transition: all 0.25s ease

.pillar-gateway--item.active .pillar-toggle--arrow
  transform: rotate(180deg)

@media (min-width: 768px)
  .pillar-gateway--inner
    flex-direction: row

  .pillar-gateway--item
    height: 350px
    flex: 49%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-evenly
    padding: 0
    border-left: none
    border-top: 5px solid transparent

  .pillar-gateway--entries
    margin: 0

  .pillar-gateway
    .pillar-toggle
      justify-content: center

    .pillar
      margin-left: 0

    .pillar-toggle--arrow
      display: none

@media (min-width: 1400px)
  .pillar-gateway
    height: 30vh

  .pillar-gateway--item
    height: 100%
    flex: 1

    &.active
      height: 100%
      padding: 0

@media (min-width: 1400px) and (max-height: 999px)
  .pillar-gateway
    height: auto

  .pillar-gateway--item
    padding: 20px 0

    &.active
      height: 100%
      padding: 20px 0

  .pillar-gateway--entries
    margin: 20px 0

.link-section
  .theme-block
    position: relative
    width: 100%
    max-height: 100%
    padding: 0
    color: #fff
    opacity: 1
    transition: none
    pointer-events: all
    overflow: visible

  .heading.section-heading
    margin-bottom: 1.5em

.filter-wrapper
  display: flex
  flex-direction: column
  justify-content: center
  margin-bottom: 4em

.filters .btn
  color: $deep-space1
  border-color: $deep-space1

  &.active, &:hover
    color: #ffffff
    background-color: $deep-space1

@media (min-width: 768px)
  .filter-wrapper
    flex-direction: row
    align-items: center

    .heading
      margin-bottom: 0

  .filters
    margin-left: auto
    margin-top: 0.5em

input[type='text']
  padding: 1em
  border-radius: 2px
  color: #003247
  background-color: #e8e8e4
  border: none
  outline: none

#trello
  select option
      background-color: $deepspace-blue !important
      color: #fff

  .ml-signup-field textarea,
  .ml-signup-field input,
  .ml-signup-field select,
  .ml-signup-field option,
  input[type='text']
    padding: 1em
    border-radius: 0
    border: solid 2px #ffffff !important
    color: #ffffff
    background-color: transparent
    border: none
    outline: none

  .ml-signup-field textarea:focus,
  .ml-signup-field input:focus,
  .ml-signup-field select:focus,
  input[type='text']:focus
    border: solid 2px $esa-teal55 !important

  #id_tool
    background-color: transparent
  
  .btn
    margin-top: 0

@media (max-width: 1024px)
  .card:hover
    transform: none
    animation: none

    .image-bg, .arrow
      transform: none
      animation: none

  .btn:hover
    transform: none
    animation: none

.agency
  p a, li a
    display: inline
    border-color: #8197a6

  p a:hover, li a:hover
    color: #8197a6

.article.agency
  .btn
    color: #8197a6

    &:hover, &.active
      color: #ffffff

  .breadcrumbs__entry
    color: $deep-space1

    &:hover
      border-color: $deep-space1

#popup-modal
  position: fixed
  z-index: 999999
  width: 100%
  height: 100vh
  left: 0
  top: 0
  overflow: auto
  padding: 0
  opacity: 0
  visibility: hidden
  pointer-events: none
  transition: all 0.25s ease

  &.active
    opacity: 1
    visibility: visible
    pointer-events: all

  &.loading
    opacity: 0.7
    visibility: visible

.header ~ .modal #modal-quit
  display: none

.dark-theme #popup-modal
  color: #003247 !important

@media (min-width: 1024px)
  .header ~ .modal > .modal__info
    padding-top: 8rem

#popup-modal .modal
  width: 100%
  overflow-y: auto

#cookie_loc
  margin: 0
  position: fixed
  z-index: 9999999
  bottom: 0
  left: 0
  right: 0

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
  .header
    display: block

  .nav
    float: left

  .brand
    float: right
    margin-top: -8px

  .pillar-gateway--item
    justify-content: center

  .pillar-gateway--entries
    margin: 20px 0

  .card .thumbnail
    background-color: #003247

  .filter-wrapper
    display: block

  .filters
    margin-left: 0
    float: right
    // margin-top: -70px

  .entry-info
    width: 100%
    display: flex
    flex-direction: row
    align-items: flex-start
    justify-content: flex-start
    margin-bottom: 0
    height: 100%

  .card
    .play-button
      margin-bottom: 0
      position: absolute
      top: 50%
      left: 50%
      margin-left: -18px
      margin-top: -18px

    .entry
      height: auto

    .meta
      margin-top: 20px

    &.press-release .entry-info
      height: auto

    &.image .heading
      margin-bottom: 100px

    &.highlight .heading
      margin-bottom: 200px

  .image-bg
    object-fit: unset
    width: auto !important
    transform: translate(-50%) scale(1.1)
    left: 50% !important

  .card:hover .image-bg
    transform: translate(-50%) scale(1.25)

  .round-thumbnail:hover .image-bg
    transform: translate(-50%) scale(1.2)

.climate-branding h1
  position: absolute
  top: 60px
  left: 0
  width: 100%
  z-index: 1001
  // border-bottom: 2px solid colours.$esa-teal
  padding-bottom: 3px
  background: linear-gradient(90deg, #00ae9d 0%, #335e6f 68%) left bottom $deepspace-blue no-repeat
  background-size: 100% 3px

  span
    z-index: 1001 !important
    color: #ffffff
    font-family: 'NotesESAbold', sans-serif
    font-size: 2rem
    letter-spacing: -1px
    background: rgb(0,0,0)
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%,  rgba(0,0,0,1) 60%, $deepspace-blue 100%)
    display: inline-block
    width: 100%
    margin-bottom: 0
    padding-bottom: 5px
    padding-top: 5px
    padding-left: 15px
    padding-right: 15px
    margin-top: 0

    &.spacer
      padding: 0 10px

.separator
  text-align: center
  padding-bottom: 4px
  margin-top: 20px
  margin-bottom: 20px
  background: linear-gradient(90deg, #00ae9d 0%, #335e6f 68%) left bottom #777 no-repeat
  background-size: 100% 10px

.separator_thin
  text-align: center
  padding-bottom: 4px
  margin-top: 0
  margin-bottom: 0
  background: linear-gradient(90deg, #00ae9d 0%, #335e6f 68%) left bottom #777 no-repeat
  background-size: 100% 10px

.separator_grey
  text-align: center
  padding-bottom: 4px
  margin-top: 20px
  margin-bottom: 20px
  background: $black50
  opacity: 0.5
  background-size: 100% 10px

@media screen and (min-width: 992px)
  .row.vertical-divider 
    overflow: hidden

  .row.vertical-divider > div[class^='col-']
    padding-bottom: 100px
    margin-bottom: -100px
    border-left: 1px solid $esa-teal
    border-right: 1px solid $esa-teal

  .row.vertical-divider div[class^='col-']:first-child 
    border-left: none

  .row.vertical-divider div[class^='col-']:last-child 
    border-right: none

.header-nav-odp
  // below is for ODP
  background-color: #000000 !important
  background: linear-gradient(to left,#003349 0%,transparent 100%)

.header-nav-site
  // below is for main Site
  background: linear-gradient(to left,transparent 0%,#000000 100%)

// This header appears immediately underneath the fixed header (.header) when the page is scrolled to the top.
// However, it scrolls with the page.
#header-nav
  padding-top: 10px
  left: 0
  width: 100%
  z-index: 1001
  padding-bottom: 0
  padding-left: 24px
  padding-bottom: 1rem
  position: absolute
  top: 60px
  left: 0

.header-esa
    font-family: NotesESAbold
    padding-left: 25px
    span
        color: #fff
        font-size: 3rem
        font-weight: 800
    a
      color: #fff
      font-size: 3rem
      font-weight: 800

      &:link, &:visited, &:hover, &:active
        text-decoration: none
        color: #fff

.header-nav-sections
    color: #ffffff
    font-size: 2rem
    font-weight: 400
    float: right
    // Vertically align the text to the middle
    display: inline-block
    vertical-align: middle
    line-height: 1.1
    padding-bottom: 1rem
    padding-top: 10px

    @media (max-width: 767.98px)
      clear: both
      text-align: center
      margin-bottom: 1rem
      background-color: $deep-space1
      margin-left: 0
      padding-left: 0
      
    
    ul
      list-style-type: none
    
    li
      float: left

    ul li
      list-style: none
      display: inline
      padding-left: 0
      padding-right: 0.5rem

      a:link
        text-decoration: none
        color: #fff

      a:visited
        text-decoration: none
        color: #fff

      a:hover
        text-decoration: underline
        color: #fff

      a:active
        text-decoration: underline
          color: #fff
        
    ul li::after
      content: ' \007C'
    ul li:last-child::after
      content: none

    @media (max-width: 767.98px)

      ul
        margin-left: -15px
        padding-left: -15px
        line-height: 3rem
      li
        float: left
      
      ul li
        clear: both
        text-align: center
        margin: auto

      ul li::after
        content: ''
      ul li:last-child::after
        content: none

.breadcrumb
    background-color: transparent
    font-size: 1.75rem
    // Vertically align the text to the middle
    // display: inline-block
    vertical-align: middle
    line-height: 1.1
    padding-bottom: 0
    
    li
      margin-left: 0

    li:last-child
      font-weight: bold

    .breadcrumb-item + .breadcrumb-item::before
        color: #fff
        font-weight: normal
        content: "\203A "

    a
        color: #fff

    a:link
      text-decoration: none
      color: #fff

    a:visited
      text-decoration: none
      color: #fff

    a:hover
      text-decoration: underline
      color: #fff

    a:active
      text-decoration: underline
      color: #fff

    .active
        color: #fff

.badge
  background: $esa-teal !important
  border-radius: 0
  font-size: 1.5rem
  font-family: NotesESAbold, sans-serif
  padding: 0.5rem 1rem 0.5rem 1rem
  margin-bottom: 5rem
  text-transform: uppercase

.alternate-background
  &:nth-child(2n)
    background: linear-gradient(to top right, #000000, #000000, #000000, $deepspace-blue, $deepspace-blue) !important

.no-background
  background: linear-gradient(to top right, #000000, #000000, #000000, $deepspace-blue, $deepspace-blue) !important
  
.highlight-alternate-background
  &:nth-child(2n)
    // background: linear-gradient(to top right, #000000, #000000, #000000, $deepspace-blue, $deepspace-blue) !important
    background: $gradient-background !important

.filter-color
    background: rgba(0,0,0,0.4) !important
    margin: 0
    padding: 0

.odp-background
  background: #000000 !important
  // background-color: #000000 !important

#feedback-label
  position: fixed
  color: #ffffff
  background: $esa-teal
  font-family: NotesESAregular, sans-serif
  padding: 1rem 3rem 1rem 3rem
  top: 30vh
  right:0
  z-index: 999
  transform: rotate(-90deg)
  transform-origin: right bottom

  a
    color: #ffffff
