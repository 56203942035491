@font-face
  font-family: 'flexslider-icon'
  src: url('../fonts/ESA/flexslider-icon.eot')
  src: url('../fonts/ESA/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('../fonts/ESA/flexslider-icon.woff') format('woff'), url('../fonts/ESA/flexslider-icon.ttf') format('truetype'), url('../fonts/ESA/flexslider-icon.svg#flexslider-icon') format('svg')
  
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'NotesESAregular'
  src: url('../fonts/ESA/NotesEsa.eot')
  src: url('../fonts/ESA/NotesEsa.woff') format('woff'), url('../fonts/ESA/NotesEsa.ttf') format('truetype'), url('../fonts/ESA/NotesEsa.svg') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'NotesESAbold'
  src: url('../fonts/ESA/NotesEsa-Bold.eot')
  src: url('../fonts/ESA/NotesEsa-Bold.woff') format('woff'), url('../fonts/ESA/NotesEsa-Bold.ttf') format('truetype'), url('../fonts/ESA/NotesEsa-Bold.svg') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'NotesStyle'
  src: url('../fonts/ESA/NotesStyle-Bold-UpdatedTf.eot')
  src: url('../fonts/ESA/NotesStyle-Bold-UpdatedTf.woff') format('woff'), url('../fonts/ESA/NotesStyle-Bold-UpdatedTf.ttf') format('truetype'), url('../fonts/ESA/NotesStyle-Bold-UpdatedTf.svg') format('svg')
  font-weight: normal
  font-style: normal
