@import 'colours'

#user-survey
    position: fixed
    bottom: 0
    right: 0
    width: 20em
    font-family: NotesESA, sans-serif
    font-size: 2rem
    color: #ffffff
    background-color: $deepspace-blue
    z-index: 999
    border-color: #00ae9d
    border-width: 3px
    border-style: solid
    border-radius: 1rem
    margin-bottom: 2rem
    margin-right: 2rem
    padding-left: 2rem
    padding-right: 2rem
    padding-bottom: 2rem

    animation-duration: 3s
    animation-name: survey-slidein

    display: none

#user-survey-question
    margin-top: 2rem
    margin-bottom: 2rem
    margin-left: auto
    margin-right: auto

#user-survey-close
    float: right
    padding: 2rem
    margin: 0
    margin-right: -2rem

#user-survey-textbox
    margin-top: 1em
    color: $deepspace-blue
    background-color: #e8e8e4
    display: block
    border-radius: 3px
    border: none
    outline: none
    width: 100%

#user-survey-textbox-submit
    margin-top: 2rem
    margin-left: auto
    padding-left: 4rem
    padding-right: 4rem
    height: 5rem
    color: $deepspace-blue
    background-color: #e8e8e4

#user-survey-textbox-submit:hover
    background-color: $esa-teal

#user-survey-textbox-question,
#user-survey-textbox-submit,
#user-survey-textbox
    display: none

#user-survey-textbox-question
    margin-top: 2em

#user-survey-rating
    width: fit-content
    margin-left: auto
    margin-right: auto

#user-survey-bottom-note,
#user-survey-bottom-note p
    font-size: 1.5rem
    margin-top: 2rem
    margin-bottom: 0
    color: #dddddd

#user-survey-bottom-note a
    color: #ffffff
    border-bottom: 1px solid #00ae9d
    box-shadow: inset 0 -1px 0 #00ae9d

    &:hover, &:focus
        background: #00ae9d

@keyframes survey-slidein
    from
        bottom: -100%

    to
        bottom: 0
