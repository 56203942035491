@import 'colours'

#overlay-menu
  display: flex
  position: fixed
  z-index: 999999
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  overflow: hidden
  // Disabled so because we've disabled the right-hand half of the menu page
  // background-color: #0b1d26
  padding: 0
  opacity: 0
  pointer-events: none
  visibility: hidden
  transition: all 0.25s ease

  &.active
    opacity: 1
    pointer-events: all
    visibility: visible

// This appears unused (from the original ESA menu)
// #overlay-menu .country .button-group a
//   line-height: 1.6rem
//
//   img
//     width: 1.6rem
//     vertical-align: bottom

#overlay-menu-wrapper
  display: flex
  width: 100vw
  transition: all 0.25s ease

  &.slide
    transform: translateX(-100%)

// Currently unused (from the original ESA menu)
// .back-menu
//   background-color: #003247
//   border-radius: 100%
//   width: 50px
//   height: 50px
//   font-size: 2.2rem
//   position: absolute
//   z-index: 99999
//   transform: translate(104vw, 20px)
//   transition: all 0.25s ease
//
//   &:hover
//     transform: translate(104vw, 20px) scale(1.1)

.close-menu
  margin: 0.8em 0 auto 1.2em
  font-size: 2.2rem
  transition: all 0.25s ease

  &:hover
    transform: scale(1.2) rotate(90deg)

#sidebar
  min-width: 100vw
  max-width: 100vw
  background-color: #0f2733
  color: #8197a6
  overflow-y: auto
  overflow-x: hidden
  transition: all 0.25s ease

#sidebar-nav
  width: 100%
  margin: 50px 0

  .menu-item
    display: block
    padding: 25px 0 25px 30px
    text-align: left
    font-family: 'NotesESAregular', sans-serif
    font-weight: normal
    font-size: 1.8rem
    transition: all 0.25s ease
    cursor: pointer
    color: #8197a6

    &:hover
      background-color: rgba(255, 255, 255, 0.02)
      color: #fff

    &.active:hover
      background-color: rgba(255, 255, 255, 0.05)

    &::before
      content: ''
      width: 12px
      height: 12px
      display: inline-block
      border-radius: 100%
      background-color: $deep-space1
      margin-right: 18px

  .menu-item--home
    padding-left: 26px
    transition: none

    &::before
      display: none

    .fa-home
      margin-right: 14px
      height: 16px

    &:hover .fa-home
      color: #8197a6

  .menu-item
    &.agency::before, &.country::before
      background-color: #8197a6

    &.science-exploration::before, &.exploring-discovering::before
      background-color: $excite-red

    &.safety-security::before, &.monitoring-safeguarding::before
      background-color: $trusty-azure

    &.applications::before, &.connecting-benefiting::before
      background-color: $esa-teal

    &.enabling-support::before, &.designing-operating::before
      background-color: $enlight-yellow

#sidebar .social-media-links
  margin-top: 0
  margin-bottom: 100px
  padding: 0 0 1.5em 1.5em

  a
    color: inherit

  > *
    margin: 0 1em 0 0

#menu-content
  position: relative
  min-width: 100vw
  max-width: 100vw
  height: 100%
  overflow-y: auto
  overflow-x: hidden
  transition: all 0.25s ease
  -webkit-overflow-scrolling: touch
  // This is here because we have nothing to put in the right-hand half of the menu page, so we make the page show
  // through
  background-color: #0b1d26
  opacity: 0.7

#overlay-menu .heading.section-heading
  margin-bottom: 0.5em

// This appears to be unused - it's from the original ESA menu
// .theme-block
//   position: absolute
//   width: 100%
//   max-height: 0
//   padding: 7em 1em
//   color: #fff
//   opacity: 0
//   transition: all 0.25s ease
//   pointer-events: none
//   overflow: hidden
//
//   &.active
//     /* Side menu heading */
//     max-height: 99999px
//     opacity: 1
//     pointer-events: all
//
//   &.agency .section-heading, &.country .section-heading
//     color: #8197a6
//
//   &.exploring-discovering .section-heading
//     color: $excite-red
//
//   &.monitoring-safeguarding .section-heading
//     color: $trusty-azure
//
//   &.connecting-benefiting .section-heading
//     color: $esa-teal
//
//   &.designing-operating .section-heading
//     color: $enlight-yellow



@media (min-width: 768px)
  #overlay-menu-wrapper.slide
    transform: none

  #sidebar
    min-width: 300px
    max-width: 300px

  #sidebar-nav .menu-item.active
    background-color: rgba(255, 255, 255, 0.05)
    color: #fff

  #menu-content
    min-width: calc(100vw - 300px)
    max-width: calc(100vw - 300px)

  .theme-block
    padding: 50px

@media (min-width: 1025px)
  #sidebar
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start

    .social-media-links
      margin-top: auto
      margin-bottom: 0
