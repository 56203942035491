@use 'fonts'
@import 'colours'

// This gives only the font-based non-SVG version of Fontawesome.
// We should instead use the Pro version. See fontawesome.js / ccikewebsite.js.
//
//$fa-font-path: '~@fortawesome/fontawesome-free/webfonts'
//@import '~@fortawesome/fontawesome-free/scss/fontawesome'
//@import '~@fortawesome/fontawesome-free/scss/solid'
//@import '~@fortawesome/fontawesome-free/scss/regular'

body
  font-family: NotesESAregular, sans-serif
  // line-height: 1.1
  font-size: 20px
  color: #3b393d
  background: #ffffff
  background-size: cover
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  overflow: hidden
  overflow-y: scroll

  &.body--hidden
    overflow: hidden

body>*
  flex-grow: 0
  flex-shrink: 0

a
  text-decoration: none
  color: #de6551
  outline: none

  &:hover, &:focus
    color: #3b393d

.ccike-image-right
  position: static
  float: right
  margin: 1rem 0 1rem 1rem

.ccike-image-left
  position: static
  float: left
  margin: 1rem 1rem 1rem 0

.ccike-image-fullwidth-right,
.ccike-image-fullwidth-centered,
.ccike-image-fullwidth
  display: block
  margin: 1rem auto 1rem auto

.ccike-image-fullwidth-nomarginright
  margin-right: 0

.img-caption
  font-size: 1em
  margin-top: 0.5em

.demo-2 a
  color: #7cc576

.demo-3
  background: #1b1b1b

.demo-4
  background: #acaad1

  a
    color: #6448a0

.hidden
  position: absolute
  overflow: hidden
  width: 0
  height: 0
  pointer-events: none

.content
  a
    text-decoration: none
    border-bottom: 1px solid $esa-teal
    box-shadow: inset 0 -1px 0 $esa-teal
    color: inherit
    transition: background 0.1s cubic-bezier(0, 174, 157,1)

  a:hover
    background: $esa-teal

  figure
    text-decoration: none
    border-bottom: 0
    box-shadow: none

    a
      border-bottom: 0
      box-shadow: none

      img
        border-bottom: 3px solid $esa-teal
        box-shadow: inset 0 -3px 0 $esa-teal
        color: inherit
        transition: background 0.1s cubic-bezier(0, 174, 157,1)


    a:hover
      background: none

  figure:hover
    background: none
    img
      background: none



  ol
    list-style-type: none
    counter-reset: item
    margin: 0
    padding: 0

  ol > li
      display: table
      counter-increment: item
      margin-bottom: 0.6em

      &::before
          content: counters(item, '.') '. '
          display: table-cell
          padding-right: 0.6em

ul
  margin-bottom: 2rem
li
  margin-left: 5rem
  margin-top: 1.5rem

  // li ol > li
  //     margin: 1em

  //     &::before
  //         content: counters(item, '.') ' '

  // li
  //     font-size: 1.2em
  //     line-height: 2.5rem
  //     margin-bottom: 1.5rem

  // li li
  //     font-size: 1em
  //     line-height: 1.2
  //     margin-top: 1.5rem

  // .btn
  //     margin-bottom: 1rem
  //     margin-top: 2rem
  //     padding-left: 4rem
  //     padding-right: 4rem
  //     height: initial
  //     min-height: 5rem
  //     font-size: 2rem
  //     font-weight: 800
  //     background-color: $deepspace-blue
  //     color: #fff
  //     text-transform: none



//////////////////////////////////
// Fixed-position (upper) header


// This is a fixed-position block at the top of the page, ie it remains in place as the user scrolls.
// To ensure that the content leaves space for it there's a normally positioned block of the same height to act as
// a space.
#header
  display: flex
  background-color: #003247
  color: #fff
  align-items: center
  height: 64px
  border-bottom: 4px solid $esa-teal
  width: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 99999
  transition: top 0.3s

.head-spacer
  height: 64px

#quicksearch-i
  font-size: 1.6rem

#search-button
  // We use padding in the button (rather than margins) and height: 100% to make the button larger than just the
  // hamburger image. This should make it easier to click on touchscreens.
  padding-right: 2rem
  height: 100%
  font-size: 2rem

#lang-button
  // We use padding in the button (rather than margins) and height: 100% to make the button larger than just the
  // hamburger image. This should make it easier to click on touchscreens.
  padding-right: 2rem
  height: 100%
  font-size: 2rem

#menu-button
  // We use padding in the button (rather than margins) and height: 100% to make the button larger than just the
  // hamburger image. This should make it easier to click on touchscreens.
  padding-left: 2.4rem
  padding-right: 2rem
  height: 100%
  font-size: 2rem

#slogan
  display: none // Invisible unless the page is >375px wide
  font-size: 1.5rem
  font-family: 'NotesESAbold', sans-serif
  font-weight: normal

@media (min-width: 375px)
  #slogan
    display: block

@media (min-width: 400px)
  #slogan
    font-size: 1.8rem

@media (min-width: 768px)
  #slogan
    font-size: 2rem

#header-esa-logo
  margin-left: auto // Right-justifies the image
  margin-right: 2.4rem
  height: 32px

img.brand
  width: 88px


/////////////////////////////
// Content


.content
  padding: 0

/* Related demos

.content--related
  font-weight: bold
  text-align: center

.media-item
  display: inline-block
  padding: 1em
  vertical-align: top
  transition: color 0.3s

.media-item__img
  max-width: 100%
  opacity: 0.3
  transition: opacity 0.3s

.media-item
  &:hover .media-item__img, &:focus .media-item__img
    opacity: 1

.media-item__title
  font-size: 1em
  margin: 0
  padding: 0.5em

/* cards wrapper and close btn

.wrapper
  overflow: auto
  width: 95%
  max-width: 1366px
  margin: 0 auto


/* Content in pages

// teal-section is the 'Contact and support' block in project index pages.
.background-section,
.clear-section,
.teal-section,
.events-section
    // font-size: 1rem
    text-align: left
    padding-top: 3rem
    padding-bottom: 2rem
    // font-size: 20px
    font-family: 'NotesESAregular', sans-serif

    h1
        font-size: 3rem
        font-weight: 800
        margin-bottom: 2rem
        // text-transform: capitalize

    h2
      font-size: 42px
      margin-bottom: 6rem
      // color: $deepspace-blue

    // p
        // line-height: 1.5
        // font-size: 1.5rem

    .content
        padding-bottom: 1rem

// This is used for the project background information in project pages.
.background-section
    color: #fff
    // background-image: linear-gradient(to bottom, #000000, #1e191d, #2e2c39, #354456, #335e6f)

    a
      text-decoration: none
      border-bottom: 1px solid $esa-teal !important
      box-shadow: inset 0 -1px 0 $esa-teal !important
      color: inherit !important
      transition: background 0.1s cubic-bezier(0, 174, 157,1) !important

    // li
    //     font-size: 1.5rem
    //     margin-bottom: 0.5rem

    a:hover
        background: $esa-teal

// This is used in the project 'latest news & events' section in project root pages, and in news-and-events
// pages.
.clear-section
    color: $deepspace-blue
    background-color: transparent

.table
  margin-top: 2rem

.cci-table
  line-height: 5rem
  thead
    th
      background: transparent
      border-bottom: solid 3px #ffffff
      font-family: NotesESAbold
      font-weight: 800
      font-size: 1.2em
  tbody>tr:nth-child(odd)>td,
  tbody>tr:nth-child(odd)>th
    background-color: $deepspace-blue
  tbody>tr:nth-child(even)>td,
  tbody>tr:nth-child(even)>th
    background-color: transparent
  tbody tr:hover td,
  tbody tr:hover th,
  .table-hover tbody tr:hover th,
  .table-hover tbody tr:hover td
    background-color: $deep-space1

.project-block
  .projects-list
    text-align: center
    font-family: NotesESAbold, sans-serif
    max-height: 600px
    ul
      list-style-type: none

      li
        border-top: solid 2px $esa-lightgrey
        border-bottom: solid 2px $esa-lightgrey
        padding-top: 1rem
        padding-bottom: 1rem
        line-height: 4rem
        margin-top: 0 !important
        margin-bottom: 0 !important
        &:hover
          background: rgba(0, 50, 71, 0.6)
          color: $esa-teal



        a
          color: #ffffff
          border-bottom: none
          box-shadow: none

        a:hover
          background: none
          color: $esa-teal
          transition: 0
