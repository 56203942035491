// Primary colours
$esa-teal: #00ae9d
$esa-teal55: #77cabd

// ECV Colours, only for gradients and colour washes
$deep-space1: #335e6f
$project-green: #008542 // Oceanic
$project-orange: #f29322 // Terrestrial
$project-blue: #1f9dd8 // Atmospheric
  
// Additional, only for graphs and tables
$excite-red: #ed1b2f
$esa-orange: #e37222
$trusty-azure: #009bdb

// Secondary colours
$dark-blue: #002664
$dark-green: #284e36
$dark-yellow: #b88b00
$esa-dark-azure: #00549f
$esa-dark-orange: #9d5116
$esa-dark-red: #822433

// Neutral colours
$black90: #404041
$black50: #939597

// Test colours
// $deepspace-blue: #022d42
$deepspace-blue: #003247

// From ESA
$enlight-yellow: #fbab18

// Unknown source
$lightgrey: #dde3e6
$lightblue: #bdcace
$esa-lightgrey: #dde3e6

// $gradient-background: linear-gradient(225deg, #003349 0%, rgba(0, 51, 73, 0.00) 100%)
$gradient-background: linear-gradient(to top right, #000000, #000000, #000000, #000000, $deepspace-blue)

// Hex to RGBA converter]
@mixin rgba-background($hexcolor, $opacity)
  background-color: $hexcolor
  background-color: rgba($hexcolor, $opacity)

@mixin rgba-background2($hexcolor, $opacity)
  background: $hexcolor
  background: rgba($hexcolor, $opacity)
