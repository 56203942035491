/*
 *** ESA Header stylesheet v5
 *** Date: 23-10-2020

// Importing the CCI KE Colours is 1 of the only 2 additional required step
@import 'colours'

// Theme color by pillar
$default: #335e6f // Deep Space -1
$science-and-exploration: #ed1b2f // Red
$safety-and-security: #009bdb // Azure
$applications: #00ae9d // Teal
$enabling-and-support: #fbab18 // Yellow

// Selected pillar
//$theme-color: $default

// Replacing theme-color for the selected pilar is the last additional step
$theme-color: $esa-teal

// Replace variable with pillar name above

.esa-header
  box-sizing: border-box
  position: relative

  // Choose between relative, absolute and fixed
  width: 100%
  height: 56px
  display: flex
  align-items: center
  justify-content: flex-start
  padding: 0 24px
  background-color: #003247
  color: #fff
  font-size: 20px
  z-index: 99999
  border:
    bottom-width: 4px
    bottom-style: solid
    color: $theme-color
  

  @media (min-width: 640px)
    height: 64px

  &__left
    display: flex
    align-items: center
    justify-content: flex-start

    > *
      margin-right: 16px

      &:last-child
        margin-right: 0

  &__right
    margin-left: auto
    line-height: 0

  &__title
    display: none
    width: 222px
    height: 12px

    @media (min-width: 640px)
      display: block

  &__link
    display: block

  &__logo
    width: 78px
    height: 28px

    @media (min-width: 640px)
      width: 89px
      height: 32px

  &__toggle
    width: auto
    height: auto
    padding: 0
    background: none
    border: none
    outline: none
    transition: all 0.25s ease

    &:hover
      cursor: pointer
      transform: scale(1.1)

    &:last-of-type
      margin-right: 24px

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
      margin-right: 10px

  .esa-header__icon
    max-width: 20px
    max-height: 20px
