@import 'colours'
@import 'shadows'

#navbarProjectInnerPhone
    margin-bottom: 7rem
    overflow-x: auto
    white-space: nowrap
    -webkit-overflow-scrolling: touch
    
    a
        font-size: 20px
        border-bottom: 0
        box-shadow: none

    li
        margin-right: 25px

#navbarProjectInnerDesktop
    margin-top: 3rem
    margin-bottom: 7rem

    a
        font-size: 20px
        border-bottom: 0
        box-shadow: none